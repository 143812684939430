<template>
  <div class="app-container payment-record-component">
    <div class="filter-line">
      <span>
        <el-button @click="getTableList" icon="el-icon-refresh-right">{{
          this.$t("commons.refresh")
        }}</el-button>
        <el-button type="primary" @click="exportHandler">{{
          this.$t("commons.export")
        }}</el-button>
      </span>
      <div
        class="header-filter-button"
        @click="dataset.filterLine.show = !dataset.filterLine.show"
        :class="
          dataset.filterLine.show ? 'filter-button-open' : 'filter-button-close'
        "
      >
        <i class="iconfont icon-loudoutu"></i>
        筛选
      </div>
    </div>
    <div>
      <finalTable
        ref="finalTableRef"
        :datas="dataset"
        @tableEventHandler="tableEventHandler"
      >
        <template v-slot:completeMonthSlot="row">
          <span
            v-if="
              row.datas.completeMonthSett == false ||
              row.datas.completeMonthSett == true
            "
          >
            <el-switch
              @change="
                changeToCardList(row.datas.orderId, row.datas.paymentStatus)
              "
              v-model="row.datas.completeMonthSett"
              active-color="#13ce66"
              :disabled="
                row.datas.settlementType == '月结' &&
                (row.datas.paymentStatus == '已支付' ||
                  row.datas.paymentStatus == '待支付' ||
                  row.datas.paymentStatus == '已关单')
                  ? true
                  : false
              "
            >
            </el-switch>
          </span>
          <span v-else>-</span>
        </template>
        <template v-slot:invoiceStatusSlot="row">
          <span
            v-if="
              row.datas.invoiceStatus == false ||
              row.datas.invoiceStatus == true
            "
          >
            <el-switch
              @change="
                changeToInvoiceList(row.datas.orderId, row.datas.invoiceStatus)
              "
              v-model="row.datas.invoiceStatus"
              active-color="#13ce66"
            >
            </el-switch>
          </span>
          <span v-else>-</span>
        </template>
      </finalTable>
      <el-dialog
        title="提示"
        :visible.sync="dialogVisible"
        width="30%"
        :show-close="false"
        :destroy-on-close="true"
        :close-on-click-modal="false"
      >
        <span>您确定要继续操作吗</span>
        <span slot="footer" class="dialog-footer">
          <el-button @click="(dialogVisible = false), (monthStatusId = null)"
            >取 消</el-button
          >
          <el-button type="primary" @click="popupVisible()">确 定</el-button>
        </span>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import finalTable from "@/components/FinalTable";
import {
  getpaidOrderList,
  paidListExport,
  editInvoiceStatus,
  editCardStatus,
} from "@/api/ruge/gsPark/customerService/paidWorkOrder";
import { dateFormat } from "@/filters/index";
export default {
  name: "invoiceRecordComponent",
  components: {
    finalTable,
  },
  data() {
    return {
      dataset: {
        paginationConfig: {
          need: true,
        },
        filterLine: {
          show: true,
        },
        selection: {
          need: false,
        },
        header: [
          { prop: "creationDate", label: "报单时间", width: "" },
          { prop: "payDate", label: "入账时间", width: "" },
          { prop: "positionDescName", label: "报修位置", width: "" },
          { prop: "ordPersonName", label: "报单人", width: "" },
          { prop: "ordPersonPhone", label: "联系电话", width: "" },
          { prop: "settlementType", label: "结算类型", width: "" },
          { prop: "totalAmount", label: "收费金额", width: "" },
          { prop: "paymentStatus", label: "支付状态", width: "" },
          { prop: "completeMonthSett", label: "月结是否已完成", width: "" },
          { prop: "invoiceStatus", label: "现结是否已开票", width: "" },
          { prop: "operation", label: "操作", width: "120" },
        ],
        tableData: [],
        // 搜索行配置
        searchLineConfig: {
          positionDescName: {
            type: "input",
            label: "报修位置",
            value: "",
            actionType: "goSearch",
            placeholder: "请输入报修位置",
            prefixIcon: "el-icon-search",
          },
          ordPersonName: {
            type: "input",
            label: "报单人",
            value: "",
            actionType: "goSearch",
            placeholder: "请输入报单人",
            prefixIcon: "el-icon-search",
          },
          ordPersonPhone: {
            type: "numberInput",
            label: "联系电话",
            value: "",
            actionType: "goSearch",
            placeholder: "请输入联系电话",
            prefixIcon: "el-icon-search",
          },
          settlementType: {
            type: "selector",
            label: "结算类型",
            actionType: "goSearch",
            value: "",
            placeholder: "请选择结算类型",
            optionList: [
              {
                label: "现结",
                value: 1,
              },
              {
                label: "月结",
                value: 2,
              },
            ],
          },
          paymentStatus: {
            type: "selector",
            label: "支付状态",
            actionType: "goSearch",
            value: "",
            placeholder: "请选择支付状态",
            optionList: [
              {
                label: "待支付",
                value: 1,
              },
              {
                label: "已支付",
                value: 2,
              },
              {
                label: "支付失败",
                value: 3,
              },
              {
                label: "已入账",
                value: 4,
              },
              {
                label: "已关单",
                value: 5,
              },
            ],
          },
          totalAmount: {
            type: "numberInput",
            label: "交易金额",
            value: "",
            actionType: "goSearch",
            placeholder: "请输入交易金额",
            prefixIcon: "el-icon-search",
          },
          completeMonthSett: {
            type: "selector",
            label: "是否已完成",
            actionType: "goSearch",
            value: "",
            placeholder: "请选择是否已完成月结",
            optionList: [
              {
                label: "是",
                value: "true",
              },
              {
                label: "否",
                value: "false",
              },
            ],
          },
          invoiceStatus: {
            type: "selector",
            label: "是否已开发票",
            actionType: "goSearch",
            value: "",
            placeholder: "请选择是否已开发票",
            optionList: [
              {
                label: "是",
                value: "SUCCESS",
              },
              {
                label: "否",
                value: "WAIT",
              },
            ],
          },

          operation: {
            type: "button",
            filterCount: "",
            actionType: "clickEvent",
            eventName: "showMoreFilters",
            label: "更多筛选",
          },
        },
        // 表格内容配置
        detailConfig: {
          completeMonthSett: {
            type: "slot",
            slotName: "completeMonthSlot",
          },
          invoiceStatus: {
            type: "slot",
            slotName: "invoiceStatusSlot",
          },
          creationDate: {
            type: "dateFormat",
          },
          payDate: {
            type: "dateFormat",
          },

          operation: {
            type: "icon",
            iconList: [
              {
                actionType: "iconClick",
                eventName: "view",
                fontSize: "14px",
                color: "#1A4CEC",
                cursorPointer: "pointer",
                iconName: "el-icon-view",
                tooltips: "详情",
              },
              {
                actionType: "iconClick",
                eventName: "jump",
                fontSize: "14px",
                color: "#1A4CEC",
                cursorPointer: "pointer",
                iconName: "el-icon-edit",
                tooltips: "查看工单",
                hideProp: "flag",
                hideValue: false,
              },
            ],
          },
        },
        // 高级搜索配置
        advanceFilterConfig: {
          creationDate: {
            type: "dateRange",
            pickerType: "date",
            label: "报单时间",
            value: [],
          },
          payDate: {
            type: "dateRange",
            pickerType: "date",
            label: "入账时间",
            value: [],
          },
          positionDescName: {
            inline: true,
            value: "",
          },
          ordPersonName: {
            inline: true,
            value: "",
          },
          ordPersonPhone: {
            inline: true,
            value: "",
          },

          settlementType: {
            inline: true,
            value: "",
          },
          totalAmount: {
            inline: true,
            value: "",
          },
          paymentStatus: {
            inline: true,
            value: "",
          },
          completeMonthSett: {
            inline: true,
            value: "",
          },
          invoiceStatus: {
            inline: true,
            value: "",
          },
        },
        pageVO: {
          current: 1,
          rowCount: 10,
          total: 0,
        },
      },
      requestParams: {
        current: 1,
        rowCount: 10,
        startTime: null,
        endTime: null,
        payStartTime: null,
        payEndTime: null,
        positionDescName: null,
        settlementType: null,
        paymentStatus: null,
        totalAmount: null,
        completeMonthSett: null,
        invoiceStatus: null,
      },
      loadingFlag: false,
      dialogVisible: false,
      monthStatusId: null,
    };
  },
  watch: {
    loadingFlag: {
      handler(val) {
        this.$refs.finalTableRef.loadingToggle(val);
      },
    },
  },
  created() {
    if (this.$route.query.goBackFlag) {
      const curSearch = sessionStorage.getItem("payment_list_query_params");
      if (curSearch) {
        const tempObj = JSON.parse(curSearch);
        this.requestParams = { ...this.requestParams, ...tempObj };
        Object.keys(this.requestParams).forEach((item) => {
          if (this.dataset.searchLineConfig[item]) {
            this.dataset.searchLineConfig[item].value =
              this.requestParams[item];
          }
        });
      }
    } else {
      sessionStorage.removeItem("payment_list_query_params");
    }
    this.getTableList();
  },
  methods: {
    openDetailDialog(rowData) {
      this.$router.push({
        path: "/gsPark/paymentInvoice/paidWorkOrder/paidWorkDetail",
        query: { orderId: rowData.orderId },
      });
    },
    jumpToRwork(id) {
      this.$router.push({
        path: "/iot/orderQuery/detail",
        query: { orderId: id },
      });
    },
    changeToCardList(id, payStatus) {
      if (payStatus == "已支付") return;
      this.dialogVisible = true;
      this.monthStatusId = id;
    },
    popupVisible() {
      editCardStatus({
        paymentStatus: "2",
        orderId: this.monthStatusId,
      }).then((res) => {
        if (res.code == 200) {
          this.dialogVisible = false;
          this.$message.success("修改月结成功！");
          this.getTableList();
        } else if (res.code == 500) {
          this.dialogVisible = false;
          this.$message(res.msg);
          this.getTableList();
        }
      });
    },
    changeToInvoiceList(id, invoiceStatus) {
      const status = invoiceStatus == true ? "SUCCESS" : "WAIT";
      editInvoiceStatus({
        invoiceStatus: status,
        orderId: id,
      }).then((res) => {
        if (res.code == 200) {
          this.$message.success("修改状态成功！");
          this.getTableList();
        } else if (res.code == 500) {
          this.$message(res.msg);
          this.getTableList();
        }
      });
    },
    tableEventHandler(datas) {
      if (datas.type === "goSearch") {
        this.requestParams.current = 1;
        this.dataset.pageVO.current = 1;
        this.dealTime(datas);
        this.dealPayTime(datas);
        this.dealCompletionStatus(datas);
        this.requestParams = { ...this.requestParams, ...datas.params };
        this.getTableList();
      } else if (datas.type === "paginationChange") {
        this.requestParams.current = datas.params.current.page;
        this.requestParams.rowCount = datas.params.current.limit;
        this.getTableList();
      } else if (datas.type === "iconClick") {
        switch (datas.eventName) {
          case "view":
            this.openDetailDialog(datas.row);
            break;
          case "jump":
            this.jumpToRwork(datas.row.orderId);
            break;
        }
      }
    },

    dealTime(datas) {
      if (datas.params.creationDate && datas.params.creationDate.length > 0) {
        this.requestParams.startTime = datas.params.creationDate[0]
          ? dateFormat(datas.params.creationDate[0]) + " 00:00:00"
          : null;
        this.requestParams.endTime = datas.params.creationDate[1]
          ? dateFormat(datas.params.creationDate[1]) + " 23:59:59"
          : null;
        delete datas.params.creationDate;
      }
      return datas;
    },
    dealPayTime(datas) {
      if (datas.params.payDate && datas.params.payDate.length > 0) {
        this.requestParams.payStartTime = datas.params.payDate[0]
          ? dateFormat(datas.params.payDate[0]) + " 00:00:00"
          : null;
        this.requestParams.payEndTime = datas.params.payDate[1]
          ? dateFormat(datas.params.payDate[1]) + " 23:59:59"
          : null;
        delete datas.params.payDate;
      }
      return datas;
    },
    dealCompletionStatus(datas) {
      if (datas.params.invoiceStatus) {
        this.requestParams.invoiceStatus =
          datas.params.invoiceStatus == true ? "SUCCESS" : "WAIT";
      }
      return datas;
    },
    getTableList() {
      this.loadingFlag = true;
      getpaidOrderList(this.requestParams)
        .then((res) => {
          res.rows.forEach((item) => {
            if (item.settlementType == 2 && item.paymentStatus == 2) {
              this.$set(item, "completeMonthSett", false);
            } else if (item.settlementType == 2 && item.paymentStatus != 2) {
              this.$set(item, "completeMonthSett", true);
            }
            if (item.settlementType == 1 && item.invoiceStatus == "SUCCESS") {
              item.invoiceStatus = true;
            } else if (
              item.settlementType == 1 &&
              item.invoiceStatus == "WAIT" &&
              item.paymentStatus == 2
            ) {
              item.invoiceStatus = false;
            } else {
              item.invoiceStatus = null;
            }

            item.settlementType = item.settlementType === 1 ? "现结" : "月结";
            if (item.paymentStatus == 1) {
              item.paymentStatus = "待支付";
            } else if (item.paymentStatus == 2) {
              item.paymentStatus = "已支付";
            } else if (item.paymentStatus == 3) {
              item.paymentStatus = "支付失败";
            } else if (item.paymentStatus == 4) {
              item.paymentStatus = "已入账";
            } else if (item.paymentStatus == 5) {
              item.paymentStatus = "已关单";
            }
          });

          this.dataset.tableData = res.rows;
          this.dataset.pageVO.total = res.total;
        })
        .catch(() => {
          this.dataset.tableData = [];
          this.dataset.pageVO.total = 0;
        })
        .finally(() => {
          this.loadingFlag = false;
        });
    },
    exportHandler() {
      paidListExport(this.requestParams).then((taskId) => {
        this.$message({
          type: "success",
          message: this.$t("message.operationSuccess"),
        });
        let exportObj = {
          taskId,
          taskName: "有偿工单记录",
          taskStatus: 0,
          rootPath: "customerWXGSPath",
        };
        this.$store.dispatch("PushExportNotice", exportObj);
      });
    },
  },
};
</script>

<style lang="less" scoped>
.payment-record-component {
  .filter-line {
    margin-bottom: 18px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    button {
      height: 36px;
    }
    .icon-loudoutu {
      font-size: 12px;
    }
    .header-filter-button {
      cursor: pointer;
      width: 84px;
      height: 36px;
      line-height: 36px;
      text-align: center;
      border-radius: 3px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
    }
    .filter-button-open {
      color: #409eff;
      background: #ecf5ff;
      border: 1px solid #c6e2ff;
    }
    .filter-button-close {
      color: #2a4158;
      background: #ffffff;
      border: 1px solid #e7e8eb;
    }
  }
  .link-span {
    cursor: pointer;
    color: #409eff;
  }
}
</style>